import {useContext, useEffect, useState} from 'react';
import {requestWrapper} from "./../components/util/helper"
import {profileConstants} from "../components/util/appConstants2";
import {
    fetchProducts,
    fetchProfile,
    getDomainOrgs,
    getUserAllowedApplications,
    savePassword, savePasswordOKta,
    saveProfileApi
} from "../api/profile-api";
import {getConfigData} from "../api/common-api";
import {getTargetUrl, isOKta, logout, trackCtaEvent} from "../utils/Utils";
import {profileConstants as profileConstants2} from "../components/util/profileConstants2";
import AppContext from "../context/AppContext";
import authService from '../services/authService'


const useProfile = (type) => {
        const {auth} = useContext(AppContext);
        const [dropDownsData, setDropDownData] = useState()
        const [disableEdit, setDisableEdit] = useState(false);//shared variable for all the sections
        const [userData, setUserData] = useState();
        const [isLoading, setIsLoading] = useState(true);
        const [apiErrorMessages, setApiErrorMessages] = useState({});
        const [isProfileFailedToLoad, setIsProfileFailedToLoad] = useState(false);

        const saveData = async (data, profileSection) => {
            const res = await requestWrapper(async () => {
                try {
                    trackCtaEvent('/www-profile', profileSection)
                    let result = await saveProfileApi(data);
                    // trackFormEvent('form submit', profileSection, 'Save', 'Profile')
                    if (checkLogout(data, profileSection)) {
                        logout(getTargetUrl())
                    } else {
                        result = await refreshProfileData();
                        return result;
                    }
                } catch (e) {
                    const errorMessage = (e && e.response && e.response.data && e.response.data.message) ? e.response.data.message : profileConstants.SYSTEM_ERROR;
                    // trackFormEvent('form submit error', 'EmailAndPasswordInfo', errorMessage)
                    return {isError: true, errorMsg: [errorMessage]}
                }

            }, setIsLoading)();

            return res;
        };
        const savePasswordApi = async (data, section) => {

            const resp = await requestWrapper(async () => {
                try {
                    const resp = isOKta()? await  savePasswordOKta(data): await savePassword(data);
                    if (resp.status === 200) {
                        logout(getTargetUrl())
                    }
                } catch (e) {
                    const errorMessage = (e && e.response && e.response.data && e.response.data.message) ? e.response.data.message : profileConstants.SYSTEM_ERROR;
                    // setApiErrorMessages({[profileConstants.PASSWORD_INFO]: [errorMessage]})
                    return {isError: true, errorMsg: [errorMessage]}
                }

            }, setIsLoading)();
            return resp;
        };
        const refreshProfileData = async () => {
            const resp = await fetchProfile();
            const appDetails = userData.appDetails;
            const user = resp && resp.data;
            if (user) {
                user.appDetails = appDetails;
            }
            setUserData(user);
            auth.setActingUser(user)
            return resp
        };
        const checkLogout = (data, section) => {
            if (section === profileConstants2.COMPANY_INFO_SECTION_ADOBE_KEY) {
                return (data.orgExportId && userData.orgExportId && data.orgExportId.toString() !== userData.orgExportId.toString())
            }
            return section === profileConstants2.PASSWORD_INFO_SECTION_ADOBE_KEY

        };


        const processConfigData = (configData) => {
            if (configData) {
                let {countries, jobFunctions, deactivateReasons, countryStateMap, oktaAppIdGroups} = configData;

                jobFunctions = jobFunctions ? jobFunctions.map((val, index) => ({id: index, value: val, label: val})) : [];
                jobFunctions.unshift({id: 'selectOne', value: '', label: 'Select One'});

                countries = countries ? countries.map((country) => ({
                    id: country.code,
                    value: country.code,
                    label: country.name
                })) : [];

                countryStateMap = countryStateMap.map((countryState) => ({
                    id: countryState.code,
                    label: countryState.name,
                    value: countryState.code,
                    states: getStates(countryState)
                }));


                deactivateReasons = deactivateReasons ? deactivateReasons.map(r => ({
                    id: r.id,
                    value: r.description,
                    label: r.description
                })) : [];
                deactivateReasons.unshift({id: 0, value: '', label: 'Select One'});

                return {countries, jobFunctions, deactivateReasons, countryStateMap, oktaAppIdGroups}
            }
        };

        const getStates = (countryState) => {
            let states = countryState.states.map((state) => ({
                id: state.code,
                label: state.name,
                value: state.code
            }));

            if (states != null && states.length > 0) {
                states.unshift({
                    id: 'selectOne',
                    value: '',
                    label: 'Select One'
                });
            }

            return states;
        }
        const getCookieValue = (name) => {
            return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
        }
        const fetchUserProducts = async () => {
            const qidSessionCookieName = ('prd' === process.env.REACT_APP_ENV_SN) ? 'QID_SESSION' : `QID_${process.env.REACT_APP_ENV_SN.toUpperCase()}_SESSION`;
            const qidSession = getCookieValue(qidSessionCookieName);
            const resp = await fetchProducts(qidSession);
            return resp.data
        };
        useEffect(() => {
            const loadProfileData = async () => {
                return await requestWrapper(async () => {
                    try {
                        const data = await Promise.all([
                            fetchProfile(), getDomainOrgs(), getUserAllowedApplications(),
                            getConfigData('jobFunctions, countries,deactivateReasons,countryStateMap, oktaAppIdGroups'), fetchUserProducts()
                        ]);


                        const profileData = (data.length > 0 && data[0]) ? data[0].data : {};
                        const domainOrgs = (data.length > 1 && data[1]) ? data[1] : [];
                        const appDetails = (data.length > 2 && data[1]) ? data[2].data.appDetails : [];
                        const configData = (data.length > 3 && data[3]) ? data[3] : [];
                        const products = (data.length > 4 && data[4]) ? data[4] : [];
                        profileData.appDetails = appDetails;
                        profileData.products = products;
                        const dropDownsData = {...(processConfigData(configData)), domainOrgs};
                        if(isOKta()){
                            const oktaJwtClaims = authService.readOktaJwt()
                            const {groups:appIds} =  oktaJwtClaims
                            if(appIds && appIds.length){
                                const {oktaAppIdGroups:oktaAppIdGroupsFromConfig} =dropDownsData

                                const matchedAppIds =  oktaAppIdGroupsFromConfig.filter(obj=>!!(appIds.find(appid=>appid===obj.id)) )
                                console.log({matchedAppIds})

                                profileData.appDetails =matchedAppIds
                            }

                        }


                        setDropDownData(dropDownsData);
                        setUserData(profileData);
                        auth.setActingUser(profileData)
                    } catch (e) {
                        const errorMessage = (e && e.response && e.response.data && e.response.data.message) ? e.response.data.message : profileConstants.SYSTEM_ERROR
                        setApiErrorMessages({common: [errorMessage]});
                        setIsProfileFailedToLoad(true)
                    }
                }, setIsLoading)()
            };
            try {
                loadProfileData()
            } catch (e) {

            }

            // eslint-disable-next-line
        }, []);


        const exports = {
            userData,
            dropDownsData,
            saveData,
            savePasswordApi,

            disableEdit,
            setDisableEdit,

            isLoading,
            isProfileFailedToLoad,
            apiErrorMessages,
            setApiErrorMessages
        };

        return {
            ...exports
        };
    }
;

export default useProfile;