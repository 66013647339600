import moment from 'moment';
import {trackEventAdobe} from "../adobe/eventTrack";
import {getSessionToken} from "../services/authService";

const jwt = require('jsonwebtoken');


export const currentYear = () => {
    return (
        moment().year()
    );
};

export const cookieExpirationTime = (seconds) => {
    const expirationSeconds = seconds ? seconds : 86400;
    return moment().add(expirationSeconds, 'seconds').valueOf();
};

export const hexEncode = (s) => {
    var chrsz = 8;
    var hexcase = 0;

    const str2binb = (str) => {
        var bin = [];
        var mask = (1 << chrsz) - 1;
        for (var i = 0; i < str.length * chrsz; i += chrsz) {
            bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - i % 32);
        }
        return bin;
    };

    const Utf8Encode = (string) => {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";

        for (var n = 0; n < string.length; n++) {

            var c = string.charCodeAt(n);

            if (c < 128) {
                utftext += String.fromCharCode(c);
            } else if ((c > 127) && (c < 2048)) {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            } else {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        }

        return utftext;
    };

    const binb2hex = (binarray) => {
        var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
        var str = "";
        for (var i = 0; i < binarray.length * 4; i++) {
            str += hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8 + 4)) & 0xF) +
                hex_tab.charAt((binarray[i >> 2] >> ((3 - i % 4) * 8)) & 0xF);
        }
        return str;
    };

    s = Utf8Encode(s);
    return binb2hex(str2binb(s));
};

export const setCookie = (name, value, secondsToExpiration) => {
    const expiresAt = cookieExpirationTime(secondsToExpiration);
    const expireString = moment(expiresAt).utc();
    const cookieValue = escape(value) || "";

    document.cookie = `${name}=${cookieValue}; expires=${expireString}`;
};

//migrated from cp-reg
//original: https://stackoverflow.com/questions/4825683/how-do-i-create-and-read-a-value-from-cookie
export const getCookie = (cookieName) => {
    let i, x, y, cookieArray = document.cookie.split(";");
    for (i = 0; i < cookieArray.length; i++) {
        x = cookieArray[i].substr(0, cookieArray[i].indexOf("="));
        y = cookieArray[i].substr(cookieArray[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x === cookieName) {
            return unescape(y);
        }
    }
    return null;
};

//TODO: leaving this alone for now, but what was the point
export const setHashCookie = () => {
    let cookieName = 'createpointInitialUrlHash';
    let cookieValue = window.location.hash;
    if (cookieValue) {
        document.cookie = cookieName + "=" + cookieValue + ";domain=qualcomm.com;path=/";
    }
};

export const getUrlParameter = (name, url) => {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(url);
    return results === null ? '' : results[1];
};

export const getUrlParams = () => {
    let params = {};
    window
        .location
        .search
        .replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
            params[key] = value;
        });

    return params;
};

export const getSupportEmail = () => {
    return process.env.REACT_APP_SUPPORT_EMAIL;
};

export const getLoginUrl = () => {
    return `${process.env.REACT_APP_API_URL}${isOKta()?process.env.REACT_APP_API_OKTA_LOGIN_ENDPOINT:process.env.REACT_APP_API_LOGIN_ENDPOINT}`;
};

export const getLogoutUri = () => {
    if(isOKta()){
        return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_OKTA_LOGOUT_ENDPOINT}`
    }
    return `${process.env.REACT_APP_REDIRECT_LOGOUT_URI}`;

};

export const getTargetUrl = () => {
    return `${process.env.REACT_APP_TARGET_RETURN_URL}`;
};

export const getRefreshTokenUrl = () => {
    return `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_TOKEN_REFRESH_ENDPOINT}`;
};
export const getTealiumUrl = () => {
    return `${process.env.REACT_APP_TEALIUM_URL}`;
};

export const trackScrollEvent = (target, page) => {
    const scrollPercentage = _get_scroll_percentage(target)
    const avgScrollPercentage = Math.floor(scrollPercentage / 25) * 25;
    if (avgScrollPercentage && avgScrollPercentage !== 0 && avgScrollPercentage <= 100) {
        if (window.adobeDataLayer) {
            const lastMatch = findMatches(window.adobeDataLayer, avgScrollPercentage);
            if (lastMatch.length === 0) {
                let adobeData = {
                    "event": "scroll_track",
                    "eventInfo": {
                        "action": `${avgScrollPercentage}`,
                        "label": "page",
                        "detail": page

                    }
                };
                trackEventAdobe(adobeData)
            }
        }
    }
}


function findMatches(array, avgScrollPercentage) {
    return array.filter(item => item.event === 'scroll_track' && item.eventInfo.action === `${avgScrollPercentage}`);
}

export const trackFooterEvent = (footerText, targetURL) => {
    let adobeData = {
        "event": "footer",
        "eventInfo": {
            "action": "click",
            "label": "footer",
            "detail": footerText,
            "location": targetURL
        }
    }
    trackEventAdobe(adobeData)
}

export const trackFormEvent = (formAction, formId, formDetail, formLocation) => {
    let adobeData = {
        "event": "form",
        "eventInfo": {
            "formAction": formAction,
            "formId": formId,
            "formDetail": formDetail
        }
    }
    if (formLocation) {
        adobeData['formLocation'] = formLocation
    }
    trackEventAdobe(adobeData)
}

export const trackCtaEvent = (url, app, moreInfo = {}) => {
    let adobeData = {
        "event": "cta",
        "eventInfo": {
            "action": "click", //EXAMPLE: device_tile, Payment options,  click
            "label": app,
            "location": url
        }
    }
    if (adobeData["eventInfo"] !== null && adobeData["eventInfo"] !== undefined && moreInfo !== null && moreInfo !== undefined) {
        Object.assign(adobeData["eventInfo"], moreInfo)
    }

    trackEventAdobe(adobeData)
}
export const trackSignUpCtaEvent = () => {
    const adobeData = {
        "event": "create account",
        "eventInfo": {
            "action": "click",
            "detail": "Sign Up New Account",
            "label": "Sign up here",
            "location": "/signup"
        }
    }
    trackEventAdobe(adobeData)
}

export const trackSignInCtaEvent = (label, detail) => {
    const adobeData = {
        "event": "sign in",
        "eventInfo": {
            "action": "click",
            "detail": detail,
            "label": label,
            "location": "/login"
        }
    };

    trackEventAdobe(adobeData)
}

/**
 * Get current browser viewpane heigtht
 */
function _get_window_height() {
    return window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight || 0;
}

/**
 * Get current absolute window scroll position
 */
function _get_window_Yscroll(target) {
    if (target.className) {
        return document.getElementsByClassName(target.className)[0].scrollTop || 0
    }

    return window.pageYOffset ||
        document.body.scrollTop ||
        document.documentElement.scrollTop || 0;
}

/**
 * Get current absolute document height
 */
function _get_doc_height(target) {
    return Math.max(
        target && target.className ? (document.getElementsByClassName(target.className)[0].scrollHeight || 0) : 0,
        document.body.scrollHeight || 0,
        document.documentElement.scrollHeight || 0,
        document.body.offsetHeight || 0,
        document.documentElement.offsetHeight || 0,
        document.body.clientHeight || 0,
        document.documentElement.clientHeight || 0
    );
}


/**
 * Get current vertical scroll percentage
 */
function _get_scroll_percentage(target) {
    return (
        (_get_window_Yscroll(target) + _get_window_height()) / _get_doc_height(target)
    ) * 100;
}

export const logout = (returnUrl) => {
    let loggedInUserSessionId;
    const token = getSessionToken();
    if (token) {
        const currentUser = jwt.decode(token);
        if (currentUser != null) {
            loggedInUserSessionId = currentUser.sessionId;
        }
        //Removing the jwt token from local storage
        localStorage.removeItem(`QID-${process.env.REACT_APP_ENV_SN}-JWT-TOKEN`);
    }
    window.location = `${getLogoutUri()}?code=${loggedInUserSessionId}&targetUrl=${returnUrl}`;


};

export const getCompanyNameWithOrgId = (comapanyName, OrgId) => {
    return `${comapanyName} (${OrgId})`;
};

export const getAdobeLaunchLibUrl = () => {
    return `${process.env.REACT_APP_ADOBE_LAUNCH_SCRIPT}`;
};
export const preHideAdobe = () => {
    if (document.head) {
//        console.log(`is author enabled -> ${document.location.href.indexOf("adobe_authoring_enabled")}`);
        if (document.location.href.indexOf("adobe_authoring_enabled") !== -1) return;
        let o = document.createElement("style");
        o.id = "alloy-prehiding";
        o.innerText = "body { opacity: 0 !important }";
        document.head.appendChild(o);
        setTimeout(() => {
            o.parentNode && o.parentNode.removeChild(o)
        }, 3000);
    }
};

export const addExtScript = (url, integrity, selector = 'body', async = true, crossOrigin = "anonymous") => {
    const scriptId = `${selector}-script`;
    const existingScript = document.getElementById(scriptId)
    if (existingScript) return existingScript;

    let script = document.createElement("script");
    script.src = url;
    script.id = scriptId;
    script.async = async;
    if (integrity) {
        script.integrity = integrity;
    }
    if (crossOrigin) {
        script.crossOrigin = crossOrigin;
    }

    document.querySelector(selector).appendChild(script);
    return script;
};
export const isOKta =()=>{
    return process.env.REACT_APP_IS_OKTA === 'true'
}