import axios from 'axios';
import {apiEndpoint} from '../config';
import {getSessionToken, isTokenExpired, isValidSession, refreshToken} from "../services/authService";
import {getCompanyNameWithOrgId, isOKta, logout} from "../utils/Utils";


const profileAxios = axios.create({});

profileAxios.interceptors.request.use(async (config) => {
    let token = getSessionToken();
    if (!token || !isValidSession(token)) {
        logout(window.location)
    } else {
        if (isTokenExpired(token)) {
            const response = await refreshToken(token);
            token = response.data ? response.data.jwtToken : null;
        }
    }
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        logout(window.location)
    } else {
        throw  error
    }
});

profileAxios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error && error.response && error.response.status === 401) {
        logout(window.location)
    } else {
        throw  error
    }
});

export const fetchProfile = async () => {
    const res = profileAxios({
        method: 'GET',
        url: apiEndpoint.userProfileEndpoint,
    })
    return res;

}
export const saveProfileApi = async (data) => {
    const res = profileAxios({
        method: 'put',
        url: apiEndpoint.userProfileEndpoint,
        data: {...data}
    })
    return res;
};
export const getDomainOrgs = async () => {

    const response = await profileAxios.get(apiEndpoint.userDomainOrgsEndpoint)
    const domainOrgs = response.data;
    return domainOrgs && domainOrgs.map(org => {
        if(isOKta()){
            return {id: org.orgId, value: org.orgId, label: getCompanyNameWithOrgId(org.name, org.orgId)}
        }
        return {id: org.exportId, value: org.exportId, label: getCompanyNameWithOrgId(org.name, org.orgId)}
    })

}
export const getUserAllowedApplications = async () => {
    return profileAxios.get(apiEndpoint.userWelcomeDetailsEndpoint);
};


// export const getDeactivateReasons = async () => {
//     const response = await profileAxios.get('/api/deactivateReason');
//     const data = response.data;
//     let deactivateReasons = [];
//     data && Object.keys(data).map((key) => deactivateReasons.push({
//         id: data[key].id,
//         value: data[key].description,
//         label: data[key].description
//     }));
//
//     const blankDefault = {id: 0, value: '', label: 'Select One'};
//     deactivateReasons.unshift(blankDefault);
//     return deactivateReasons;
// };

// export const getJobFunctions = async () => {
//     const response = await profileAxios.get(`/api/jobFunctions`);
//     const data = response.data;
//     const jobFunctions = [];
//     jobFunctions.push({id: 'selectOne', value: '', label: 'Select One'});
//     data.map((val, index) => jobFunctions.push({id: index, value: val, label: val}));
//     return jobFunctions
// };
export const savePassword = async (data) => {
    return profileAxios({
        method: 'POST',
        url: `/api/password/change`,
        data: {...data}
    })
};
export const savePasswordOKta = async (data) => {
    return profileAxios({
        method: 'PUT',
        url: `/api/v2/password/change`,
        data: {...data}
    })
};

export const getPreferences = async () => {
    let data = await profileAxios.get(apiEndpoint.userPreferencesEndpoint);
    return data;
};

export const getQnsSettings = async () => {
    let response = await profileAxios.get(apiEndpoint.userQnsSettingsEndpoint);
    /*let data = {
        "frequency":"monthly",
        "docTypes":["hwDocs", "toolDocs"],
        "docClassifications":["announcemnets", "dataSheets"]
    }*/
    /*let data = {
        "frequency":"monthly",
        "docTypes":["hwDocs", "toolDocs", "codeDocs"],
        "docClassifications":["announcemnets", "dataSheets", "processChange", "deviceGuides", "securityBulletins", "releaseNotes", "otherDocClassifications"]
    }*/
    return response && response.data;
};

export const getWelcomeDetails = async () => {
    return profileAxios.get(apiEndpoint.userWelcomeDetailsEndpoint);
};

export const savePreferences = async (data) => {
    const response = await profileAxios({
        method: 'put',
        url: apiEndpoint.userPreferencesEndpoint,
        data: {...data}
    });
    return response;
};

export const saveQnsSettings = async (data) => {
    const response = await profileAxios({
        method: 'put',
        url: apiEndpoint.userQnsSettingsEndpoint,
        data: {...data}
    });
    return response;
};

export const getAppAreaOptions = async () => {
    const response = await profileAxios.get('/api/appAreaOption');
    const data = response.data;
    return data;
};
export const getAoiOptions = async () => {
    const response = await profileAxios.get('/api/aoiOption');
    const data = response.data;
    return data;
};
export const fetchSubscriptions = async () => {
    const response = await profileAxios.get(`${apiEndpoint.subscriptionEndpoint}`);
    const data = response.data;
    return data;
};
export const deleteSubscription = async (pdpId, pagePath) => {
    const response = await profileAxios.delete(`${apiEndpoint.subscriptionEndpoint}/pdp/${pdpId}`, {params: {pagePath:btoa(pagePath)}});
    return response;
};
export const fetchProducts = async (token) => {
    const response = await profileAxios({url: `${apiEndpoint.productsEndpoint}`, method: 'GET', params:{token:token}});
    return response;
};
export const getUserTargetUrl = async () => {
    const response = await profileAxios.get(`${apiEndpoint.userTargetEndpoint}`);
    return response && response.data
};
export  const saveDeactivateAccount = async (data) => {
    const response = await profileAxios({
        method: 'POST',
        url: `${apiEndpoint.deactivateEndpoint}`,
        data: {...data}
    });

    return response;
};

export const fetchUserPdps = async (token,text) => {
    const response = await profileAxios({url: `/api/userpdps`, method: 'GET', params:{sessionId:token, searchText:text, type:'userpdps'}});
    return response;
};
export const addSubscription = async (pdpId, pagePath) => {
    const response = await profileAxios.put(`${apiEndpoint.subscriptionEndpoint}/pdp/${pdpId}`,  {pagePath:(pagePath)});
    return response;
};





