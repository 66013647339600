import {Form, withFormik} from "formik";
import React, {useContext, useEffect} from "react";
import {profileConstants} from "../../util/profileConstants2";
import {ColoredLine, ErrorText, PanelHeader} from "../HelperComponents";
import {checkTokenValidity} from "../../../services/authService";
import EmailAndPasswordInfoFormFields from "./EmailAndPasswordInfoFormFields";
import * as Yup from "yup";
import {passwordRegex} from "../../util/validation/ValidationSchema";
import {isOKta, trackCtaEvent} from "../../../utils/Utils";
import {isValidImpersonation} from "../../util/helper";
import AppContext from "../../../context/AppContext";
import useProfile from "../../../hooks/useProfile2";

const validationSchema = Yup.object().shape({
    currentPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.CURRENT_PWD)),
    newPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.NEW_PWD))
        .max(50, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.NEW_PWD, 50))
        .matches(passwordRegex, profileConstants.isValidPasswordMsg2(profileConstants.FIELD_LABELS.NEW_PWD)),
    confirmPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.VERIFY_PWD))
        .test('passwords-match', profileConstants.passwordMatchMsg, function (value) {
            return this.parent.newPassword === value;
        }),
});

const validationSchemaOkta = Yup.object().shape({

    newPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.NEW_PWD))
        .max(50, profileConstants.maxLengthMsg(profileConstants.FIELD_LABELS.NEW_PWD, 50))
        .matches(passwordRegex, profileConstants.isValidPasswordMsg2(profileConstants.FIELD_LABELS.NEW_PWD)),
    confirmPassword: Yup
        .string()
        .required(profileConstants.isNotEmptyMsg(profileConstants.FIELD_LABELS.VERIFY_PWD))
        .test('passwords-match', profileConstants.passwordMatchMsg, function (value) {
            return this.parent.newPassword === value;
        }),
});


const EmailAndPasswordInfoBasicForm = ({...props}) => {
    const profileStore = useProfile();
    const userData = profileStore.userData;
    const {appStore} = useContext(AppContext);
    const impersonationStatus = appStore.impersonationStatus
    const previousPage = '/login';
    useEffect(() => {
        if (props.status.isEditMode && userData !== null && userData !== undefined) {
            const visitor = {
                'country_code': userData.country_code,
                'org_id': userData.org.orgId,
                'org_name': userData.org.name,
                'qcguid': userData.qcGuid,
                'user_name': userData.username,
                'loggedIn': "logged_in",
                'user_domain': userData.username && userData.username.indexOf("@") > -1 ? userData.username.slice(userData.username.indexOf("@") + 1) : 'qualcomm.com'
            }
            if (isValidImpersonation(impersonationStatus)) {
                visitor['impersonating'] = true
                visitor['impersonator'] = impersonationStatus.impersonator
            }

            // trackPageLoadEvent('profile', 'EmailAndPasswordInfo', previousPage, visitor, {})
            window.pageChange('profile', 'EmailAndPasswordInfo', previousPage, visitor, {})
        }
    }, [props.status.isEditMode, userData, impersonationStatus]);

    return (
        <>
            <Form onSubmit={props.handleSubmit} className="Bizible-Exclude">
                <PanelHeader
                    name={"emailAndPasswordInfo"}
                    isLaptop={props.isLaptop}
                    label={'Email and password'}
                    isEdit={props.status.isEditMode}
                    disableEdit={props.disableEdit}
                    hideControls={false}
                    handleEditButton={() => {
                        checkTokenValidity();
                        props.setStatus({isEditMode: true});
                        props.toggleOtherSectionsEditBtn();
                        trackCtaEvent('EmailAndPasswordInfo', 'Edit')

                    }}
                    handleCancelButton={() => {
                        props.toggleOtherSectionsEditBtn();
                        props.resetForm();
                        props.setStatus({isEditMode: false});
                        trackCtaEvent('EmailAndPasswordInfo', 'Cancel')
                    }}/>

                {/*API Error Messages*/}
                {props.status && props.status.isEditMode && props.status.isError && props.status.errorMsg &&
                    props.status.errorMsg.map((err, key) => (
                        <ErrorText key={key} w={100}>{err}</ErrorText>)
                    )}

                <ColoredLine/>
                <EmailAndPasswordInfoFormFields isFormSubmitted={props.submitCount > 0} {...props}
                                                isEditMode={props.status.isEditMode}/>
            </Form>
        </>
    )
}

export const EmailAndPasswordInfoForm = withFormik({
    enableReinitialize: true,
    validationSchema: isOKta ?validationSchemaOkta:validationSchema,
    mapPropsToValues: ({formValues}) => {
        return formValues;
    },
    mapPropsToStatus: ({isLaptop, isInternalUser}) => {
        if (isInternalUser) {
            return {isEditMode: false}
        }
        return {isEditMode: !isLaptop}
    },
    handleSubmit: async (values, {props, setStatus}) => {
        const {currentPassword, newPassword, username} = values;
        const resp = await props.saveData({
            currentPassword,
            newPassword,
            username
        }, profileConstants.EMAIL_AND_PASSWORD_INFO_ADOBE_KEY);
        if (resp.isError) {
            setStatus({isEditMode: true, isError: true, errorMsg: resp.errorMsg})
        } else {
            setStatus({isEditMode: false});
            props.toggleOtherSectionsEditBtn();

        }
    }
})(EmailAndPasswordInfoBasicForm);
